.data{
    min-width: 755px;
    height: 325px;
    margin-right: 2%;
}

.dataTitle{
    margin-left: 35px !important;
}

.datacontent{
    display: flex;
    flex-direction: row;
    margin-left: 37px;
}

.contentTitle{
    font-size: 17px;
    font-weight: bold;
}

.orderTitle{
    margin-bottom: 8px;
}

.comparison{
    display: flex;
    flex-direction: row;
}

.compLeft{
    margin-right: 80px;
}

.compMid{
    margin-right: 60px;
}

.compMid,
.compRight{
    margin-top: 2px;
}

.compValue{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.arrow{
    width: 15px;
    height: 15px;
}

.money{
    font-size: 28px;
    font-weight: bold;
    color: rgba(0, 0, 209, 0.826);
}

.compText{
    font-size: 16px;
    font-weight: bold;
}

.progress{
    margin-right: 45px;
    background-color: rgba(0, 171, 250, 0.727) !important;
}

.progressbar{
    width: 500px;
    height: 120px;
}

.progText{
    margin-left: 5px;
}

.progBot{
    margin-top: 15px;
}

.progressData{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.progValue{
    padding-right: 50px;
    font-size: 15px;
    font-weight: bold;
}