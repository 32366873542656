.income{
    min-width: 445px;
    height: 260px;
    margin-right: 30px;
}

.incomeStat{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
}

.totalIncome{
    font-size: 20px;
    font-weight: bold;
    margin-right: 105px;
}

.blueCircle{
    background-color: #59C0E4;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.yellowCircle{
    background-color: #F7B321;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.incomeText{
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 20px;
}

.barChart{
    padding-left: 10px;
    height: 180px !important;
    width: 430px !important;
}