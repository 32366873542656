.performance {
    display: flex;
    flex-direction: column;
    padding: 3%;
}

.top {
    margin-bottom: 2.5%;
}

.top,
.bottom {
    display: flex;
    flex-direction: row;
}

.data,
.reservation,
.income,
.orderCount {
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.title{
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 8%;
}