.Navigation {
  flex: 3.5;
  padding: 3.1% 2% 0 0;
  display: flex;
  flex-direction: column;
}

.options {
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navButton {
  cursor: pointer;
  margin-left: 6%;
  font-size: 20px;
  color: rgb(143, 143, 143);
  border: 0;
  background-color: rgba(255, 255, 255, 0);
  padding-bottom: 5px;
}

.active {
  color: rgb(255, 252, 252);
  border: 0;
  border-bottom: 2px solid rgb(255, 252, 252);
  background-color: rgba(255, 255, 255, 0);
}

.selectedButton {
  color: rgb(255, 252, 252);
  border: 0;
  border-bottom: 1px solid rgb(255, 252, 252);
  background-color: rgba(255, 255, 255, 0);
}

.content {
  height: 678px;
  width: 1090px;
  border-radius: 10px;
  margin: 3% 0;
  background-color: rgb(255, 255, 255);
}
