.contact{
    cursor: pointer;
    align-items: center;
    margin-left: 13%;
    margin-right: 7%;
    padding: 2.5% 0;
    padding-left: 2%;
    border-bottom: 1px dashed #E3E5FF;
}

.selectedContact{
    background-color: #E9E9E9;
}

.contactImg{
    width: 40px;
    height: 40px;
}

.contactInfo{
    margin-left: 9%;
}

.contactName{
    font-size: 1em;
}

.contactLastMsg{
    color: #979797;
    font-size: 0.8em;
}

.contact {
    position: relative;
  }

  .flex {
    display: flex;
  }
  
  .contactHeader {
    width: 13em;
    display: flex;
    justify-content: space-between;
    position: relative;  /* 设置为relative使得子元素可以相对于此元素进行定位 */
}
  
  .unreadMessageCount {
    width: 18px;
    height: 18px;
    background-color: red;
    border-radius: 50%;
    position: absolute;  /* 使用绝对定位 */
    right: 4px;  /* 将元素向右偏移 */
    top: 11px;  /* 将元素向上偏移 */
    color: white;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    padding: 0 5px;
}

  