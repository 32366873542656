.Button {
  cursor: pointer;
}

.pageButton {
  margin: 0 0.3%;
  padding: 0 0.5%;
  border: 2px solid #0066ff;
  border-radius: 5px;
  color: #0066ff;
  font-size: 10px;
  min-width: 30px; /* or whatever width you prefer */
  text-align: center;
}

.pageSelected {
  display: flex;
  justify-content: center;
  padding: 0 !important;
  width: 30px;
  background-color: #0066ff;
  color: white;
}

.pageSelect {
  margin-right: 5%;
  margin-top: 6%;
  justify-content: flex-end;
}
