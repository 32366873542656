html,
body {
  margin: 0;
  padding: 0;
  background-color: rgb(74, 74, 251);
  height: 100vh;
}

#root {
  height: 100vh;
}
