.Info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 7%;
}

abbr {
  text-decoration: none !important;
  cursor: auto !important;
}

.react-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 13% 0% 10% 0;
}

.react-calendar__navigation {
  width: 83%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 10px;
}

.react-calendar__navigation__label {
  background-color: #f5f5f500;
  border: 0;
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.react-calendar__navigation__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  border: 0;
  width: 20px;
  height: 20px;
  padding: 0 5px;
  margin: 0 5px;
}

.react-calendar__month-view__weekdays {
  padding: 2px 13px 0px 38px;
}

.react-calendar__month-view__weekdays__weekday {
  text-transform: uppercase;
  color: #fff9f978;
  font-size: 10px;
  border: 0;
  padding: 0;
  padding-bottom: 8px;
  text-decoration: none;
  border-bottom: none;
  margin: 0px 0px 0px 0px;
}

.react-calendar__month-view__days {
  padding-left: 32px;
  padding-right: 22px;
}

.react-calendar__tile {
  width: 35px;
  height: 35px;
  background-color: #f5f5f500;
  color: white;
  font-size: 10px;
  border: 0;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0px 0px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #fff9f964;
}

.react-calendar__tile--active {
  background-color: white;
  color: rgb(74, 74, 251);
}

.react-calendar__tile,
.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  cursor: pointer;
}

.Profile,
.NotificationBox {
  padding-left: 31px;
}
