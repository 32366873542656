.staff{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.staffTitle{
    margin-bottom: 5px;
}

.ayi{
    height: 30px;
    width: auto;
    padding-top: 5px;
}

.ayiName{
    font-size: 17px;
    font-weight: bold;
    margin-left: 10px;
    height:20px
}

.ayiInfo{
    display: flex;
    flex-direction: row;
    margin-left: 8px;
}

.ayiOrder{
    display: flex;
    flex-direction: row;
}

.ayiInfoText{
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
}