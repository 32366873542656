.chatContent {
  height: 78%;
}

.custInfo {
  font-size: 24px;
  margin-left: 4%;
  margin-bottom: 2%;
}

.messages {
  height: 80%;
}

.wrapper {
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  /* min-height: 100%; */
  max-height: 100%;
  margin-right: 5%;
  margin-left: 3%;
}

.chatImg {
  height: 30px;
  width: 30px;
}

.rProfPic,
.sProfPic {
  margin-top: 4px;
}

.rProfPic {
  margin-right: 1%;
}

.sProfPic {
  margin-left: 1%;
}

.msg {
  max-width: 40%;
}

.padLeft {
  width: 30px;
  margin-right: 1%;
}

.padRight {
  width: 30px;
  margin-left: 1%;
}

.rightMsg {
  width: 100%;
  justify-content: flex-end;
}

.custMsg {
  background-color: #e2ebff;
  color: #222222;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  display: inline-block; /* 让div只占用其内容所需的空间 */
  max-width: 300px; /* 限制最大宽度以防止内容过长时溢出 */
  word-wrap: break-word; /* 长单词或连续字符可能会导致文本溢出，这个属性可以防止这种情况 */
}

.memberMsg {
  background-color: #3e47df;
  color: white;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  display: inline-block;
  max-width: 300px;
  word-wrap: break-word;
}

.inputBox {
  height: 20%;
  border-top: 2px solid #f4f4f4;
}

.Buttons {
  padding: 2% 5% 0 5%;
  width: 100%;
}

.utilityButton {
  height: 30px;
  width: auto;
}

.sendButton {
  align-items: flex-end;
  margin-left: 20px;
  height: 30px;
  width: auto;
}

.utilityButton,
.sendButton {
  cursor: pointer;
}

.inputText {
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 60%;
  overflow-y: scroll;
  padding: 0 5%;
  font-size: 14px;
  margin-top: 10px;
}

.input::placeholder {
  text-align: left;
}

/* .input:focus{
    outline: none;
} */


