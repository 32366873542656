.selectionBar {
  margin-left: 3.6%;
  height: 30px;
  background-color: #f4f4f4;
  border-radius: 10px;
  justify-content: space-around;
}

.selection {
  border-radius: 10px;
  cursor: pointer;
  height: 100%;
  width: auto;
  padding: 0 20px;
}

.orderTrackingText {
  margin-left: 9.2%;
}

.selectionText,
.orderTrackingText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 100%;
}

.selected {
  color: white;
  background-color: #3e47df;
}

.tracking {
  height: 100%;
  padding-top: 4.2%;
}

.trackingTitle {
  font-size: 20px;
  margin-left: 6.3%;
}

/* .timeSelect{
    margin-left: 6.3%;
} */

.typeSelect,
.statusSelect {
  margin-top: 1.4%;
}

.orderTable {
  height: 70%;
}

.orderTop {
  height: 82%;
}

.orderList {
  width: 100%;
  margin: 4.2% 0 0 4%;
  font-size: 12px;
}

.orderNum {
  justify-content: center;
  min-width: 77px;
  margin-right: 54px;
}

.orderType {
  min-width: 65px;
  margin-right: 49px;
  justify-content: center;
}

.orderName {
  min-width: 65px;
  margin-right: 11px;
  justify-content: center;
}

.custName {
  justify-content: center;
  min-width: 130px;
  margin-right: 16px;
}

.serviceMember {
  justify-content: space-around;
  min-width: 65px;
  margin-right: 49px;
}

.orderStatus {
  justify-content: center;
  min-width: 76px;
  margin-right: 55px;
}

.createTime {
  justify-content: center;
  min-width: 76px;
  margin-right: 22px;
}

.fee {
  justify-content: center;
  min-width: 98px;
  margin-right: 35px;
}

.actions {
  justify-content: center;
  min-width: 38px;
}

.pageNum {
  margin-right: 0.6%;
}
