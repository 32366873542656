.reservation{
    min-width: 255px;
}

.reserveContent{
    display: flex;
    flex-direction: row;
}

.reserveLeft{
    width: 175px;
    height: 230px;
}

.totalReserve{
    font-size: 23px;
    font-weight: bold;
}

.reserved{
    color: grey;
    font-size: 12px;
    margin-bottom: 15px;
}

.graphTitle{
    font-size: 12px;
    font-weight: bold;
    margin-left: 30px;
}

.totalReserve,
.reserved{
    margin-left: 23px;
}

.reserveRight{
    width: 60px;
}

.monthSelection{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 10px;
}

.monthSelection,
.reserve{
    font-size: 12px;
    font-weight: bold;
}

.resTop{
    margin-top: 12px;
}

.reserveCount{
    font-size: 10px;
    font-weight: bold;
}

.details{
    cursor: pointer;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 27px;
}

.doughnutChart{
    margin-left: 15px;
    width: 150px;
    height: 150px;
}