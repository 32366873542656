.order {
  width: 100%;
  margin-top: 3.2%;
  margin-left: 4%;
  align-items: center;
  font-size: 12px;
}

.greyCircle {
  background-color: grey;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.editButton {
  height: 18px;
  width: auto;
  margin-right: 2%;
}

.orderButtons {
  align-items: center;
}

.await {
  background-color: #c0f3de;
}

.service {
  background-color: #add8e6;
}

.finished {
  background-color: #f9d994;
}

.cancelled {
  background-color: #f99494;
}

.await,
.service,
.finished,
.cancelled {
  border-radius: 5px;
}
