.chat {
  margin-top: 3%;
}

.chatSelect {
  width: 100%;
  margin-left: 2%;
}

.contactList {
  flex: 1;
  height: 560px;
  overflow-y: scroll;
  margin-top: 3%;
  border-right: 2px solid #f4f4f4;
}

.chatScreen {
  flex: 2.5;
  margin-top: 3%;
  height: 560px;
}
