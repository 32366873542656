.selectionBar {
  margin-left: 3.6%;
  height: 30px;
  background-color: #f4f4f4;
  border-radius: 10px;
  justify-content: space-around;
}

.selection {
  border-radius: 10px;
  cursor: pointer;
  height: 100%;
  width: auto;
  padding: 0 20px;
}

.empTrackingText {
  margin-left: 6%;
}

.selectionText,
.empTrackingText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 100%;
}

.selected {
  color: white;
  background-color: #3e47df;
}

.tracking {
  height: 100%;
  padding-top: 4.2%;
}

.emptrackingTitle {
  font-size: 20px;
  margin-left: 6%;
}

/* .timeSelect{
      margin-left: 6.3%;
  } */

.typeSelect,
.statusSelect {
  margin-top: 1.4%;
}

.empTable {
  height: 70%;
}

.empTop {
  height: 82%;
}

.empList {
  width: 100%;
  margin: 4.2% 0 0 4%;
  font-size: 12px;
}

.empNum {
  display: flex;
  justify-content: center;
  width: 7%;
  margin-right: 2%;
}

.empName {
  display: flex;
  justify-content: center;
  width: 8%;
  margin-right: 3%;
}

.empProj {
  display: flex;
  justify-content: center;
  width: 5%;
  margin-right: 1%;
}

.empOrderId {
  display: flex;
  justify-content: center;
  width: 14%;
  margin-right: 1.5%;
}

/* .serviceMember {
  justify-content: space-around;
  width: 5%;
  margin-right: 4.5%;
} */

.empStatus {
  display: flex;
  justify-content: center;
  width: 7%;
  margin-right: 3%;
}

.empContact {
  display: flex;
  justify-content: center;
  width: 10%;
  margin-right: 3%;
}

.createTime {
  display: flex;
  justify-content: center;
  width: 7%;
  margin-right: 2%;
}

.serviceCount {
  display: flex;
  justify-content: center;
  width: 9%;
  margin-right: 2.2%;
}

.actions {
  display: flex;
  justify-content: center;
  width: 3.5%;
}

.pageNum {
  margin-right: 0.6%;
}
