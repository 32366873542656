.login-input::placeholder {
  padding-left: 5px;
  color: rgb(159, 159, 159);
}

.login-input,
.login-button {
  height: 45px;
}

.title {
  justify-content: flex-end;
}
