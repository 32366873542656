.custTable {
  min-height: 73%;
}

.custList {
  width: 100%;
  margin: 2% 0 0 4.6%;
  font-size: 12px;
}

.custID {
  justify-content: center;
  width: 7%;
  margin-right: 0%;
}

.custName2 {
  justify-content: center;
  width: 14%;
  margin-right: 0%;
}

.defaultAddr {
  justify-content: center;
  width: 10%;
  margin-right: 1%;
}

.contactNum {
  justify-content: center;
  width: 12%;
  margin-right: 0.7%;
}

.email {
  justify-content: center;
  width: 14%;
  margin-right: 2.4%;
}

.registTime {
  justify-content: center;
  width: 8%;
  margin-right: 4.5%;
}

.reserveCount2 {
  justify-content: center;
  width: 5%;
  margin-right: 3.8%;
}

.actions2 {
  justify-content: center;
  width: 7%;
}


