.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.data-item {
  margin-left: 100px;
}

.word-wrap {
  word-wrap: break-word;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal {
  position: relative;
  background-color: #fff;
  outline: none;
  padding: 20px;
  margin: auto;
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
}
