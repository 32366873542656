/* .NotificationBox{
    border-radius: 20%;
} */

.notiTitle{
    width: 230px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 12%;
}

.notiImg{
    width: 30px;
    height: 100%;
    margin-top: 0.3em;
}

.notiContent{
    color: white;
    display: flex;
    flex-direction: column;
    margin-left: 1.5em;
}

.notiContext{
    margin-top: 0.3em;
    font-size: 0.8em;
}

.notiName{
    padding-left: 0.1em;
}

.notif{
    display: flex;
    flex-direction: row;
    margin-bottom: 2.1em;
}

/* .Notification{

} */

.NotificationBox .badge {
    justify-content: center;  /* 水平居中 */
    align-items: center;  /* 垂直居中 */
    font-size: 11px;
    font-weight: 600;
    border-radius: 50%;
    background-color: red;
    width: 22px;
    height: 22px;
    margin-left: 8px;
    text-align: center;
  }
  