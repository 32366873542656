.orderCount {
  min-width: 555px;
}

.orderLegend {
  margin-top: 10px;
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.darkBlueCircle {
  background-color: #3a47c3;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.orangeCircle {
  background-color: #ff6f09;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.orderText {
  display: flex;
  width: 60px;
  font-size: 12px;
  margin-left: 6px;
  margin-right: 15px;
}

.yearSelection {
  margin-left: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
}

.lineChart {
  padding-left: 10px;
  height: 185px !important;
  width: 530px !important;
}
