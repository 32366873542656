.App {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ref {
  position: absolute;
  top: 0;
  width: 1440px;
  height: 822px;
  width: 100vw;
  opacity: 0.2;
}

.lightBlueText {
  color: #59c0e4;
}

.blueText {
  color: #3e47df;
}

.yellowText {
  color: #f7b321;
}

.orangeText {
  color: #ff6f09;
}

.greyText {
  color: #8b9292;
}
